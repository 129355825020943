<script>
import Icon from '../components/Icon.vue'
import AvatarIcon from '../components/AvatarIcon.vue'
import TopHeader from '@/components/TopHeader.vue';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

export default {
    name: 'teamEdit',
    mixins: [UtilitiesMixin],
    components: {TopHeader, AvatarIcon, Icon},

    mounted () {
        this.id = this.$route.params.id
        this.getTeam()
    },

    data() {
        return {
            id: null,
            team: null
        }
    },

    methods: { 
        getTeam(){
            this.$axios
            .get(this.$api + 'team/' + this.id)
            .then(response => {
                this.team = response.data.data
            })
        }   
        
    },

}
</script>

<template>
    <div class="page-settings">

        <TopHeader />

        <!-- Dashboard -->
        <main v-if="team" class="container main-content mt64">
            <div class="page-header">
                <h1 class="page-title title-xl mb8">{{team.name}}</h1>
                <p class="page-description">Trabalhar com amigos é sempre melhor! :)</p>
            </div>

            <section class="my-teams mt64">
                <h2 class="h2">Seus times</h2>

            </section>
    
        </main>

    </div>
</template>

<style lang="sass">

.main-content
    padding-bottom: 64px

.teams-list
    display: flex

    .team-item
        padding: $mgSm
        border: 1px solid $cG2
        border-radius: 4px
        display: flex
        flex-direction: column
        width: 200px
        height: 240px
        margin-right: $mgSm      
        box-shadow: 0 0 4px rgba(0,0,0,.15)  

        .team-name
            font-weight: bold
            font-size: $fzSm

        .team-description
            font-size: $fzXs
            color: $cG5
            line-height: 120%

        .team-forms
            font-size: $fzSm
            color: $cG5
        

        .team-members
            display: flex
            margin-top: auto

            .member-avatar
                outline: 2px solid $cWhite
                margin-left: -5px

    
        &.add-new
            align-items: center
            justify-content: center
            color: $cG3

            .icon
                font-size: $fzXl
                margin-bottom: $mgXs

            &:hover
                color: $cPrimary







</style>